<template>
  <b-card no-body>
    <b-card-body class="text-center">
      <div class="truncate">
        <div>
         <b-spinner variant="success" type="grow" v-if="statistic==0 "/>
        <h3 class="mb-25 font-weight-bolder" v-else>
                <div class="text-center">
       <b-avatar size="sm" src="https://www.yawww.io/Yawww_icon.svg"/>
      {{statistic}}
                </div>
        </h3>
        </div>
       
        <span>Floor Price</span>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardBody, BAvatar,BSpinner,BLink,BRow,BCol} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardBody,
    BAvatar,BSpinner,BLink,BRow,BCol
  },
  props: {
    statistic: {
      type: [Number, String],
      required: true,
    },
  },
}
</script>
