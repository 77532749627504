<template>
  <div>
    <b-card no-body v-if="cambio == 1">
      <b-card-header>
        <b-row>
          <b-col lg="1" md="2" sm="2" cols="12">
               <b-avatar size="80px" :src="collection.icon_url" />
          </b-col>
          <b-col lg="8" md="7" sm="7" cols="12" align-self="center"> 
          <b-card-title class="mb-1">
          {{ collection.name }}
        </b-card-title>
        <b-card-sub-title>
          {{collection.description}}
        </b-card-sub-title>
          </b-col>
          <b-col lg="3" md="3" sm="3" cols="12" align-self="end">
             <div class="text-right">
              <b-link
                :href="collection.twitter"
                target="_blank"
                class="text-white"
              >
                <b-icon icon="BIconTwitter" />
              </b-link>
              <b-link
                :href="collection.website"
                target="_blank"
                class="text-white ml-1"
              >
                <b-icon icon="BIconGoogle" />
              </b-link>
              <b-link
                :href="collection.discord"
                target="_blank"
                class="text-white ml-1"
              >
                <b-icon icon="BIconDiscord" />
              </b-link>
            </div>
          </b-col>
        </b-row>

      </b-card-header>
    </b-card>
    <b-row class="match-height">
      <b-col sm="12" md="4" lg="4">
           <statistic-card-floor-price
            v-if="cambio == 1"
          :statistic="collection.floor_prices[0].floor_price"
        />
      </b-col>
      <b-col sm="12" md="4" lg="4">
         <statistic-card-horizontal
          v-if="cambio == 1"
          color=""
          icon=""
          :statistic="collection.total_sale_listing_count"
          statistic-title="Listed"
        />
      </b-col>
      <b-col sm="12" md="4" lg="4">
          <statistic-card-horizontal
          v-if="cambio == 1"
          color=""
          icon=""
          :statistic="collection.size"
          statistic-title="Collection Size"
        />
      </b-col>
      </b-row>
      <b-row class="match-height">
        <b-col sm="12" md="4" lg="4">
        <statistic-card-horizontal
          v-if="cambio == 1"
          color=""
          icon=""
          :statistic="collection.volumes[1].volume_last_1_days"
          statistic-title="1d Volume"
        />
      </b-col>
      <b-col sm="12" md="4" lg="4">
        <statistic-card-horizontal
          v-if="cambio == 1"
          color=""
          icon=""
          :statistic="collection.volumes[1].volume_last_7_days"
          statistic-title="7d Volume"
        />
      </b-col>
      <b-col sm="12" md="4" lg="4">
        <statistic-card-horizontal
          v-if="cambio == 1"
          color=""
          icon=""
          :statistic="collection.volumes[1].volume_last_30_days"
          statistic-title="30d Volume"
        />
      </b-col>
      </b-row>
    <component :is="cambio1 == 1 ? 'b-card' : 'div'"> 
       <b-tabs content-class="mt-2" pills>
            <b-tab 
    active>
    <template #title>
          <feather-icon
            icon="BarChart2Icon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Analytics</span>
        </template>
        <b-row>
          <b-col lg="6" md="12" sm="12">
          <apex-line-area-chart :chartTitle="'Floor Price by Day'"
       :cseries="info" :labels="labels" :key="cambio1" :estado="true" :numerograph="0"/>
          </b-col>
            <b-col lg="6" md="12" sm="12">
      <apex-multipe-bar-chart :chartTitle="'Volume History'" :cseries="infoVo" 
      :labels="labelsVo" :key="cambio2" :color="['#00E396']" :numerograph="0"/>
    </b-col>
    </b-row>
    </b-tab>
       </b-tabs>
    </component>
  </div>
</template>
<script>
import {
  BCard,
  BCol,
  BRow,
  BAvatar,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BLink,
  BIcon,
  BIconDiscord,
  BIconTwitter,
  BIconGoogle,
  BTabs,
  BTab,
  BCardSubTitle
} from 'bootstrap-vue';
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue';
import StatisticCardFloorPrice from '@core/components/statistics-cards/StatisticCardFloorPrice.vue';
import ApexLineAreaChart from "@/components/charts/apex-chart/ApexLineAreaChart.vue"
import ApexMultipeBarChart from "@/components/charts/apex-chart/ApexMultipleBarChart.vue"
export default {
  components: {
    BCard,
    BCol,
    BRow,
    BAvatar,
    StatisticCardHorizontal,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BLink,
    BIcon,
    BIconDiscord,
    BIconTwitter,
    BIconGoogle,
    StatisticCardFloorPrice,
    BTabs,
    BTab,ApexLineAreaChart,ApexMultipeBarChart,BCardSubTitle
  },
  data() {
    return {
      collection: {},
      cambio: 0,
      cambio1:0,
      cambio2:0,
      labels:[],
      info:[],
      labelsVo:[],
      infoVo:[]
    };
  },
  mounted() {
    this.getDataCollection();
    this.getFloorHistory()
    this.getVolumeHistory()
  },
  methods: {
    getDataCollection: function () {
      const vm = this;
      const axios = require('axios');
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      axios
        .get(
          'https://api.yawww.io/api/collections/index?order=volume1&direction=desc',
          config,
        )
        .then(function (response) {
        response.data.forEach(function(item){
    if(item.name === 'DeGods'){
       vm.collection =item;
       vm.cambio = 1;
        return;
    }  
});})
.catch(err => {
          console.log(err);
        });
    },
    getFloorHistory:function(){
      const vm = this;
      const axios = require('axios');
        const options = {
  day: '2-digit',
  month: 'short',
  year: 'numeric'
  }
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      axios
        .post(
          '/api/v1/sel/tkfpht',
          {},
          config,
        )
        .then(function (response) {
          vm.info.push({name:'Floor',data:response.data.info})
          for (let x = 0; x < response.data.info.length; x++) {
             const date = new Date(response.data.labels[x]) 
              var d=new Intl.DateTimeFormat('en-US', options).format(date)
             vm.labels.push(d)
          }
          vm.cambio1=1
        })
        .catch(err => {
          console.log(err);
        });
    },
    getVolumeHistory:function(){
       const vm = this;
      const axios = require('axios');
        const options = {
  day: '2-digit',
  month: 'short',
  year: 'numeric'
  }
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      axios
        .post(
          '/api/v1/sel/cvlg',
          {},
          config,
        )
        .then(function (response) {
        console.log(response.data);
             vm.infoVo.push({name:'Volume',data:response.data.info})
          for (let x = 0; x < response.data.info.length; x++) {
             const date = new Date(response.data.labels[x]) 
              var d=new Intl.DateTimeFormat('en-US', options).format(date)
             vm.labelsVo.push(d)
          }
          vm.cambio2=1
        })
        .catch(err => {
          console.log(err);
        });
    }
  },
};
</script>
<style lang="scss">
@import '@core/scss/vue/libs/chart-apex.scss';
</style>