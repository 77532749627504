<template>
  <b-card no-body>
    <b-card-body class="text-center">
      <div class="truncate">
        <div>
         <b-spinner variant="success" type="grow" v-if="statistic==0 || statistic=='$0'"/>
         
        <h3 class="mb-25 font-weight-bolder" v-else>
          <div v-if="web">
            <h4>
           <b-link :href="statistic" class="font-weight-bolder " target="_blank" style="color:#D0D2D6">
            {{ statistic }}
            </b-link></h4>
          </div>
          <div v-else>
             {{ statistic }}
          </div>
        </h3>
        </div>
       
        <span>{{ statisticTitle }}</span>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardBody, BAvatar,BSpinner,BLink} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardBody,
    BAvatar,BSpinner,BLink
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    statistic: {
      type: [Number, String],
      required: true,
    },
    statisticTitle: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'primary',
    },
    web:{
      type:Number
    }
  },
}
</script>
